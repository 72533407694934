import { useEffect } from "react";
import "./googletranslate.css";

let p = 0
const GoogleTranslate = () => {
  useEffect(() => {

    window.scrollTo(0, 0)
    if (p == 0) {
      var addScript = document.createElement("script");
      addScript.setAttribute(
        "src",
        "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
      );
      document.body.appendChild(addScript);
      window.googleTranslateElementInit = googleTranslateElementInit;
      p++;
    }

  }, []);

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        includedLanguages: 'en,es',
        autoDisplay: false
      },
      "google_translate_element"
    );
  };

  return (
    <div className="translate-container">
      <div id="google_translate_element"></div>
    </div>
  );
};

export default GoogleTranslate;