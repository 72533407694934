import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import React, { Suspense } from "react";
import "./App.css";
import config from "./config";
import ScrollToTop from "./coreFIles/scrolltotop";
import PageNotFound from "./generalPages/Pagenotfound";
import Home from "./generalPages/Home";
import Sitemap from "./generalPages/sitemap";
import DestinationDetails from "./generalPages/destinationDetails";
import SuggestionsDetails from "./generalPages/suggestionsDetails";
import Login from "./generalPages/login";
import Signup from "./generalPages/signup";
import AboutUs from "./generalPages/aboutUs";
import AboutUs2 from "./generalPages/aboutUs2";
import AboutUsForApp from "./generalPages/AboutUsForApp";
import AboutUsDetailForApp from "./generalPages/AboutUsDetailForApp";
import Contactus from "./generalPages/contactus";
import Blog from "./generalPages/blog";
import Blog2 from "./generalPages/blog2";
import Blog2ForApp from "./generalPages/Blog2ForApp";
import BlogDetailForApp from "./generalPages/BlogDetailForApp";
import Blogdetail from "./generalPages/blogdetail";
import PromiseDetail from "./generalPages/PromiseDetail";
import PromiseDetailForApp from "./generalPages/PromiseDetailForApp";
import PrivacyPolicy from "./generalPages/privacyPolicy";
import PrivacyPolicyForApp from "./generalPages/PrivacyPolicyforApp";
import Faq from "./generalPages/Faq";
import FaqForApp from "./generalPages/faqForApp";
import TermsConditions from "./generalPages/termsConditions";
import TermsConditionForApp from "./generalPages/termsConditionsForApp";
import Resetpassword from "./generalPages/resetpassword";
import Mobileverification from "./generalPages/mobileverification";
import Passwordsuccesfully from "./generalPages/passwordsuccesfullyreset";
import Forgetpassword from "./generalPages/forgetpassword";
import Subscriptionplan from "./generalPages/subscriptionplan";
import Seolanding from "./generalPages/seolanding";
import PropertyDetails from "./generalPages/propertyDetails";
import VehicleDetails from "./generalPages/vehicleDetails";
// -------------User Module Start---------------
import UserLogin from "./generalPages/usermodule/userlogin";
import UserSignup from "./generalPages/usermodule/usersignup";
import Userforgetpassword from "./generalPages/usermodule/userforgetpassword";
import Propertylist from "../src/afterLoggedin/commanComponents/propertylist";
import PropertyDetail from "../src/afterLoggedin/commanComponents/propertydetail";
import VehicleDetail from "../src/afterLoggedin/commanComponents/vehicledetail";
import Payment from "../src/afterLoggedin/commanComponents/payment";
import AppPayment from "../src/afterLoggedin/commanComponents/appPayment";
import VehiclePayment from "../src/afterLoggedin/commanComponents/vehiclepayment";
import AppVehiclepayment from "../src/afterLoggedin/commanComponents/appVehiclepayment";
import AppSubscriptionplan from "../src/generalPages/subscriptionplanForApp";
import Thankyou from "../src/afterLoggedin/commanComponents/thankyou";
import PaymentSuccess from "../src/afterLoggedin/commanComponents/paymentSuccess";
import PaymentError from "../src/afterLoggedin/commanComponents/paymentError";
import Aboutusdetail from "./generalPages/AboutUsDetails";
import OurMission from "./generalPages/OurMission";
import OurVision from "./generalPages/OurVision";
import OurMissionForApp from "./generalPages/OurMissionForApp";
import OurVisionForApp from "./generalPages/OurVisionForApp";
import WhatWeOffer from "./generalPages/WhatWeOffer";
import WhatWeOfferForApp from "./generalPages/WhatWeOfferForApp";
import AccountDeletion from "./generalPages/Accountdeletion";
// -------------Staff Dashboard-----------------------
import Dashboard from "./afterLoggedin/staffComponents/Dashboard";
import Vehicle from "./afterLoggedin/staffComponents/Dashboard/Vehicle";
import Service from "./afterLoggedin/staffComponents/Dashboard/Service";
import Financedashboard from "./afterLoggedin/staffComponents/Dashboard/Finance";
import Profitloss from "./afterLoggedin/staffComponents/Dashboard/Finance/profitloss";
import Employee from "./afterLoggedin/staffComponents/Dashboard/Finance/employee/employee";
import PaymentList from "./afterLoggedin/staffComponents/Dashboard/Finance/employee/PaymentList";
import AddPayment from "./afterLoggedin/staffComponents/Dashboard/Finance/employee/AddPayment";
import Addexpenses from "./afterLoggedin/staffComponents/Dashboard/Finance/addexpenses";
import Expenses from "./afterLoggedin/staffComponents/Dashboard/Finance/expenses";
import Supplier from "./afterLoggedin/staffComponents/Dashboard/Finance/supplier";
import Receivable from "./afterLoggedin/staffComponents/Dashboard/Finance/receivable";
import Tax from "./afterLoggedin/staffComponents/Dashboard/Finance/tax";
import Cash from "./afterLoggedin/staffComponents/Dashboard/Finance/cash";
import BalanceSheet from "./afterLoggedin/staffComponents/Dashboard/Finance/BalanceSheet";
import InvoiceDetails from "./afterLoggedin/staffComponents/Dashboard/Finance/invoice/InvoiceDetails";
import InvoiceList from "./afterLoggedin/staffComponents/Dashboard/Finance/invoice/InvoiceList";
import AddInvoice from "./afterLoggedin/staffComponents/Dashboard/Finance/invoice/AddInvoice";
import Createcheque from "./afterLoggedin/staffComponents/Dashboard/Finance/createcheque";
import Addbankdeposit from "./afterLoggedin/staffComponents/Dashboard/Finance/addbankdeposit";
import Createestimate from "./afterLoggedin/staffComponents/Dashboard/Finance/createestimate";
import Chartofaccount from "./afterLoggedin/staffComponents/Dashboard/Finance/chartofaccount";
import Createaccount from "./afterLoggedin/staffComponents/Dashboard/Finance/createaccount";
import CreateBill from "./afterLoggedin/staffComponents/Dashboard/Finance/CreateBill";
import MakePayment from "./afterLoggedin/staffComponents/Dashboard/Finance/MakePayment";
import BankDepositList from "./afterLoggedin/staffComponents/Dashboard/Finance/BankDepositList";
import EstimateList from "./afterLoggedin/staffComponents/Dashboard/Finance/EstimateList";
import ChequeList from "./afterLoggedin/staffComponents/Dashboard/Finance/ChequeList";

// -------------User Module End---------------
// import VehicleManualBooking from "./afterLoggedin/commanComponents/VehicleManualBooking";

//-----------------------------|| VEHICLE ||----------------------------

import FinanceVehicleDashboard from "./afterLoggedin/staffComponents/vehicleDashboard/FinanceVehicleDashboard"
import VehicleExpense from "./afterLoggedin/staffComponents/vehicleDashboard/VehicleExpense";
import VehicleSupplier from "./afterLoggedin/staffComponents/vehicleDashboard/VehicleSupplier";
import VehicleReceivable from "./afterLoggedin/staffComponents/vehicleDashboard/VehicleReceivable";
import AddVehicleInvoice from './afterLoggedin/staffComponents/vehicleDashboard/Invoice/AddVehicleInvoice'
import InvoiceVehicleList from './afterLoggedin/staffComponents/vehicleDashboard/Invoice/InvoiceVehicleList'
import VehicleInvoiceDetail from "./afterLoggedin/staffComponents/vehicleDashboard/Invoice/VehicleInvoiceDetail";
import VehicleEmployee from "./afterLoggedin/staffComponents/vehicleDashboard/vehicleEmployees/VehicleEmployee";
import VehicleMakePayment from "./afterLoggedin/staffComponents/vehicleDashboard/VehicleMakePayment";
import VehicleCreateBill from "./afterLoggedin/staffComponents/vehicleDashboard/VehicleCreateBill";
import VehicleBankDepositList from "./afterLoggedin/staffComponents/vehicleDashboard/VehicleBankDepositList";
import AddVehicleBankDeposit from "./afterLoggedin/staffComponents/vehicleDashboard/AddVehicleBankDeposit";
import AddVehicleCheque from "./afterLoggedin/staffComponents/vehicleDashboard/AddVehicleCheque";
import VehicleChequeList from "./afterLoggedin/staffComponents/vehicleDashboard/VehicleChequeList";
import VehicleEstimateList from "./afterLoggedin/staffComponents/vehicleDashboard/VehicleEstimateList";
import AddVehicleEstimate from "./afterLoggedin/staffComponents/vehicleDashboard/AddVehicleEstimate";
import JournalEntry from "./afterLoggedin/staffComponents/Dashboard/Finance/JournalEntry";
import AddJournalEntry from "./afterLoggedin/staffComponents/Dashboard/Finance/AddJournalEntry";
import VehicleJournalEntry from "./afterLoggedin/staffComponents/vehicleDashboard/VehicleJournalEntry";
import AddVehicleJournalEntry from "./afterLoggedin/staffComponents/vehicleDashboard/AddVehicleJournalEntry";
import ProductAndService from "./afterLoggedin/staffComponents/Dashboard/Finance/ProductAndService";
import AddBill from "./afterLoggedin/staffComponents/Dashboard/Finance/AddBill";
import BillList from "./afterLoggedin/staffComponents/Dashboard/Finance/BillList";
import BillPayment from "./afterLoggedin/staffComponents/Dashboard/Finance/BillPayment";
import BillPaymentList from "./afterLoggedin/staffComponents/Dashboard/Finance/BillPaymentList";
import EditJournalEntry from "./afterLoggedin/staffComponents/Dashboard/Finance/EditJournalEntry";
import EditBill from "./afterLoggedin/staffComponents/Dashboard/Finance/EditBill";
import PurchaseOrderList from "./afterLoggedin/staffComponents/Dashboard/Finance/PurchaseOrderList";
import AddPurchaseOrder from "./afterLoggedin/staffComponents/Dashboard/Finance/AddPurchaseOrder";
import EditPurchaseOrder from "./afterLoggedin/staffComponents/Dashboard/Finance/EditPurchaseOrder";
import Cookies from "js-cookie";

//-----------------------------|| VEHICLE END ||----------------------------

const PropertyOwner = React.lazy(() =>
  import("./afterLoggedin/userroute/PropertyOwner")
);
const UsersRoutes = React.lazy(() =>
  import("./afterLoggedin/userroute/UsersRoutes")
);
// const UsersVehicle = React.lazy(() => import('./afterLoggedin/userroute/UserVehicle'));
const Explorer = React.lazy(() => import("./afterLoggedin/userroute/Explorer"));


function ProtectedRoute({ element, allowedTypes }) {
  const loginData = Cookies.get("loginSuccessPropertyOwner")
    ? JSON.parse(Cookies.get("loginSuccessPropertyOwner"))
    : {};

  const loginType = loginData.loginType;

  if (allowedTypes.includes(loginType)) {
    return element;
  } else {
    // Redirect based on loginType
    const redirectPath =
      loginType == 2
        ? `${config.baseurl}staffdashboard`
        : loginType == 3
          ? `${config.baseurl}financedashboard`
          : `${config.baseurl}`;

    return <Navigate to={redirectPath} replace />;
  }
}

function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop>
          <Routes>
           
            <Route path={`${config.baseurl}sitemap`} element={<Sitemap />} />
            <Route path={`${config.baseurl}`} element={<Home />} />
            <Route path={`${config.baseurl}paymentSuccess`} element={<PaymentSuccess />} />
            <Route path={`${config.baseurl}paymentError`} element={<PaymentError />} />
            <Route path={`${config.baseurl}destinationDetails/:id`} element={<DestinationDetails />} />
            <Route path={`${config.baseurl}propertyDetails/:id`} element={<PropertyDetails />} />
            <Route path={`${config.baseurl}suggestionsDetails/:id`} element={<SuggestionsDetails />} />
            <Route path={`vehicleDetails/:id`} element={<VehicleDetails />} />
            <Route path={`${config.baseurl}about`} element={<h1>Welcome to about page</h1>} />
            <Route path={`${config.baseurl}Signup`} element={<Signup />} />
            <Route path={`${config.baseurl}login`} element={<Login />} />
            <Route path={`${config.baseurl}forgetpassword`} element={<Forgetpassword />} />
            <Route
              path={`${config.baseurl}resetpassword`}
              element={<Resetpassword />}
            />
            <Route
              path={`${config.baseurl}mobileverification`}
              element={<Mobileverification />}
            />
            <Route
              path={`${config.baseurl}passwordsuccesfullyreset`}
              element={<Passwordsuccesfully />}
            />
            <Route
              path={`${config.baseurl}subscriptionplan`}
              element={<Subscriptionplan />}
            />
            <Route
              path={`${config.baseurl}accountDeletion`}
              element={<AccountDeletion />}
            />
            <Route
              path={`${config.baseurl}seolanding`}
              element={<Seolanding />}
            />
            <Route path={`${config.baseurl}thank-you`} element={<Thankyou />} />
            {/* <Route path={`${config.baseurl}aboutUs`} element={<AboutUs />} /> */}
            <Route path={`${config.baseurl}aboutUs`} element={<AboutUs2 />} />
            <Route
              path={`${config.baseurl}AboutUsData`}
              element={<AboutUsForApp />}
            />
            <Route
              path={`${config.baseurl}contactus`}
              element={<Contactus />}
            />
            {/* <Route path={`${config.baseurl}blog`} element={<Blog />} /> */}
            <Route path={`${config.baseurl}blog`} element={<Blog2 />} />
            <Route
              path={`${config.baseurl}BlogData`}
              element={<Blog2ForApp />}
            />
            <Route
              path={`${config.baseurl}BlogDetail/:id`}
              element={<BlogDetailForApp />}
            />
            <Route
              path={`${config.baseurl}blog_detail/:id`}
              element={<Blogdetail />}
            />
            <Route
              path={`${config.baseurl}aboutus-detail`}
              element={<Aboutusdetail />}
            />
            <Route
              path={`${config.baseurl}aboutUsDetail`}
              element={<AboutUsDetailForApp />}
            />
            <Route
              path={`${config.baseurl}ourMission`}
              element={<OurMission />}
            />
            <Route
              path={`${config.baseurl}OurMissionDetail`}
              element={<OurMissionForApp />}
            />
            <Route
              path={`${config.baseurl}ourVision`}
              element={<OurVision />}
            />
            <Route
              path={`${config.baseurl}OurVisionDetail`}
              element={<OurVisionForApp />}
            />
            <Route
              path={`${config.baseurl}whatWeOffer/:id`}
              element={<WhatWeOffer />}
            />
            <Route
              path={`${config.baseurl}WhatWeOfferDetail/:id`}
              element={<WhatWeOfferForApp />}
            />
            <Route
              path={`${config.baseurl}promiseDetail/:id`}
              element={<PromiseDetail />}
            />
            <Route
              path={`${config.baseurl}PromiseDetails/:id`}
              element={<PromiseDetailForApp />}
            />
            <Route
              path={`${config.baseurl}privacyPolicy`}
              element={<PrivacyPolicy />}
            />
            <Route
              path={`${config.baseurl}PrivacyPolicyData`}
              element={<PrivacyPolicyForApp />}
            />
            <Route path={`${config.baseurl}faq`} element={<Faq />} />
            <Route
              path={`${config.baseurl}faqForApp`}
              element={<FaqForApp />}
            />
            <Route
              path={`${config.baseurl}termsConditions`}
              element={<TermsConditions />}
            />
            <Route
              path={`${config.baseurl}TermsConditionData`}
              element={<TermsConditionForApp />}
            />

            {/* // -------------User Module Start--------------- */}
            <Route
              path={`${config.baseurl}usersignup`}
              element={<UserSignup />}
            />
            <Route
              path={`${config.baseurl}userlogin`}
              element={<UserLogin />}
            />
            <Route
              path={`${config.baseurl}userforgetpassword`}
              element={<Userforgetpassword />}
            />
            <Route
              path={`${config.baseurl}propertylist/:location/:numberOfAdults/:checkin/:checkout/:numberOfChildren/:numberOfInfants/:numberOfPets/:propertyPurchaseTypeId`}
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  {" "}
                  <Propertylist />{" "}
                </Suspense>
              }
            />
            {/* <Route
              path={`${config.baseurl}propertydetail/:id/:numberOfAdults/:checkin/:checkout/:numberOfChildren/:numberOfInfants/:numberOfPets/:propertyPurchaseTypeId/:roomType`}
              element={<PropertyDetail />}
            /> */}
            <Route
              path={`${config.baseurl}propertydetail`}
              element={<PropertyDetail />}
            />
            <Route
              path={`${config.baseurl}vehicledetail`}
              element={<VehicleDetail />}
            />
            {/* <Route
              path={`${config.baseurl}payment/:id/:numberOfAdults/:checkin/:checkout/:numberOfChildren/:numberOfInfants/:numberOfPets/:roomType`}
              element={<Payment />}
            /> */}

            <Route
              path={`${config.baseurl}payment`}
              element={<Payment />}
            />

            <Route
              path={`${config.baseurl}appPayment`}
              element={<AppPayment />}
            /> {/* Web view for mobile app */}

            <Route
              path={`${config.baseurl}vehiclepayment`}
              element={<VehiclePayment />}
            />

            <Route
              path={`${config.baseurl}appVehiclepayment`}
              element={<AppVehiclepayment />}
            />

            <Route
              path={`${config.baseurl}appSubscriptionplan`}
              element={<AppSubscriptionplan />}
            />

            {/* // -------------User Module End--------------- */}
            <Route
              path={`${config.baseurl}${config.loginurl}/*`}
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  {" "}
                  <PropertyOwner />{" "}
                </Suspense>
              }
            />

            <Route
              path={`${config.baseurl}${config.loginurluser}/*`}
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  {" "}
                  <UsersRoutes />{" "}
                </Suspense>
              }
            />
            {/* <Route path={`${config.baseurl}${config.uservehicle}/*`} element={<Suspense fallback={<div>Loading...</div>}> <UsersVehicle /> </Suspense>} /> */}
            <Route
              path={`${config.baseurl}${config.explorer}/*`}
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  {" "}
                  <Explorer />{" "}
                </Suspense>
              }
            />
            <Route path={`/*`} element={<PageNotFound />} />

            {/* // -------------Staff Components Start--------------- */}

            <Route path={`${config.baseurl}staffdashboard`} element={<ProtectedRoute element={<Dashboard />} allowedTypes={[1, 2, 3]} />} />
            <Route path={`${config.baseurl}vehicledashboard`} element={<ProtectedRoute element={<Vehicle />} allowedTypes={[1, 2, 3]} />} />
            <Route path={`${config.baseurl}servicedashboard`} element={<ProtectedRoute element={<Service />} allowedTypes={[1, 3]} />} />
            <Route path={`${config.baseurl}financedashboard`} element={<ProtectedRoute element={<Financedashboard />} allowedTypes={[1, 3]} />} />
            <Route path={`${config.baseurl}journalentry`} element={<ProtectedRoute element={<JournalEntry />} allowedTypes={[1, 3]} />} />
            <Route path={`${config.baseurl}addjournalentry`} element={<ProtectedRoute element={<AddJournalEntry />} allowedTypes={[1, 3]} />} />
            <Route path={`${config.baseurl}editjournalentry/:id`} element={<ProtectedRoute element={<EditJournalEntry />} allowedTypes={[1, 3]} />} />
            <Route path={`${config.baseurl}profitloss`} element={<ProtectedRoute element={<Profitloss />} allowedTypes={[1, 3]} />} />
            <Route path={`${config.baseurl}employee`} element={<ProtectedRoute element={<Employee />} allowedTypes={[1, 3]} />} />
            <Route path={`${config.baseurl}paymentlist/:id`} element={<ProtectedRoute element={<PaymentList />} allowedTypes={[1, 3]} />} />
            <Route path={`${config.baseurl}addpayment/:id`} element={<ProtectedRoute element={<AddPayment />} allowedTypes={[1, 3]} />} />
            <Route path={`${config.baseurl}addexpenses`} element={<ProtectedRoute element={<Addexpenses />} allowedTypes={[1, 3]} />} />
            <Route path={`${config.baseurl}expenses`} element={<ProtectedRoute element={<Expenses />} allowedTypes={[1, 3]} />} />
            <Route path={`${config.baseurl}supplier`} element={<ProtectedRoute element={<Supplier />} allowedTypes={[1, 3]} />} />
            <Route path={`${config.baseurl}productandservice`} element={<ProtectedRoute element={<ProductAndService />} allowedTypes={[1, 3]} />} />
            <Route path={`${config.baseurl}invoicedetail/:id`} element={<ProtectedRoute element={<InvoiceDetails />} allowedTypes={[1, 3]} />} />
            <Route path={`${config.baseurl}invoicelist`} element={<ProtectedRoute element={<InvoiceList />} allowedTypes={[1, 3]} />} />
            <Route path={`${config.baseurl}addinvoice`} element={<ProtectedRoute element={<AddInvoice />} allowedTypes={[1, 3]} />} />
            <Route path={`${config.baseurl}receivable`} element={<ProtectedRoute element={<Receivable />} allowedTypes={[1, 3]} />} />
            <Route path={`${config.baseurl}tax`} element={<ProtectedRoute element={<Tax />} allowedTypes={[1, 3]} />} />
            <Route path={`${config.baseurl}cash`} element={<ProtectedRoute element={<Cash />} allowedTypes={[1, 3]} />} />
            <Route path={`${config.baseurl}balancesheet`} element={<ProtectedRoute element={<BalanceSheet />} allowedTypes={[1, 3]} />} />

            {/* New pages */}
            <Route path={`${config.baseurl}chequelist`} element={<ProtectedRoute element={<ChequeList />} allowedTypes={[1, 3]} />} />
            <Route path={`${config.baseurl}createcheque`} element={<ProtectedRoute element={<Createcheque />} allowedTypes={[1, 3]} />} />
            <Route path={`${config.baseurl}bankdepositelist`} element={<ProtectedRoute element={<BankDepositList />} allowedTypes={[1, 3]} />} />
            <Route path={`${config.baseurl}addbankdeposit`} element={<ProtectedRoute element={<Addbankdeposit />} allowedTypes={[1, 3]} />} />
            <Route path={`${config.baseurl}estimatelist`} element={<ProtectedRoute element={<EstimateList />} allowedTypes={[1, 3]} />} />
            <Route path={`${config.baseurl}createestimate`} element={<ProtectedRoute element={<Createestimate />} allowedTypes={[1, 3]} />} />
            <Route path={`${config.baseurl}chartofaccount`} element={<ProtectedRoute element={<Chartofaccount />} allowedTypes={[1, 3]} />} />
            <Route path={`${config.baseurl}createaccount`} element={<ProtectedRoute element={<Createaccount />} allowedTypes={[1, 3]} />} />
            {/* <Route path={`${config.baseurl}vehiclemanualbooking`} element={<ProtectedRoute element={<VehicleManualBooking />} allowedTypes={[1,3]} />} /> */}
            <Route path={`${config.baseurl}createbill/:id`} element={<ProtectedRoute element={<CreateBill />} allowedTypes={[1, 3]} />} />
            <Route path={`${config.baseurl}makepayment/:id`} element={<ProtectedRoute element={<MakePayment />} allowedTypes={[1, 3]} />} />
            <Route path={`${config.baseurl}addbill`} element={<ProtectedRoute element={<AddBill />} allowedTypes={[1, 3]} />} />
            <Route path={`${config.baseurl}editbill/:id`} element={<ProtectedRoute element={<EditBill />} allowedTypes={[1, 3]} />} />
            <Route path={`${config.baseurl}billlist`} element={<ProtectedRoute element={<BillList />} allowedTypes={[1, 3]} />} />
            <Route path={`${config.baseurl}billpaymentlist`} element={<ProtectedRoute element={<BillPaymentList />} allowedTypes={[1, 3]} />} />
            <Route path={`${config.baseurl}billpayment`} element={<ProtectedRoute element={<BillPayment />} allowedTypes={[1, 3]} />} />
            <Route path={`${config.baseurl}purchaseorderlist`} element={<ProtectedRoute element={<PurchaseOrderList />} allowedTypes={[1, 3]} />} />
            <Route path={`${config.baseurl}addpurchaseorder`} element={<ProtectedRoute element={<AddPurchaseOrder />} allowedTypes={[1, 3]} />} />
            <Route path={`${config.baseurl}editpurchaseorder/:id`} element={<ProtectedRoute element={<EditPurchaseOrder />} allowedTypes={[1, 3]} />} />

            {/* New Vehicle pages */}
            <Route path={`${config.baseurl}financevehicledashboard`} element={<ProtectedRoute element={<FinanceVehicleDashboard />} allowedTypes={[1, 3]} />} />
            <Route path={`${config.baseurl}vehicleexpense`} element={<ProtectedRoute element={<VehicleExpense />} allowedTypes={[1, 3]} />} />
            <Route path={`${config.baseurl}vehiclesupplier`} element={<ProtectedRoute element={<VehicleSupplier />} allowedTypes={[1, 3]} />} />
            <Route path={`${config.baseurl}vehiclereceivable`} element={<ProtectedRoute element={<VehicleReceivable />} allowedTypes={[1, 3]} />} />
            <Route path={`${config.baseurl}vehicleemployee`} element={<ProtectedRoute element={<VehicleEmployee />} allowedTypes={[1, 3]} />} />
            <Route path={`${config.baseurl}addvehicleinvoice`} element={<ProtectedRoute element={<AddVehicleInvoice />} allowedTypes={[1, 3]} />} />
            <Route path={`${config.baseurl}vehicleinvoicedetail/:id`} element={<ProtectedRoute element={<VehicleInvoiceDetail />} allowedTypes={[1, 3]} />} />
            <Route path={`${config.baseurl}invoicevehiclelist`} element={<ProtectedRoute element={<InvoiceVehicleList />} allowedTypes={[1, 3]} />} />
            <Route path={`${config.baseurl}vehiclecreatebill/:id`} element={<ProtectedRoute element={<VehicleCreateBill />} allowedTypes={[1, 3]} />} />
            <Route path={`${config.baseurl}vehiclemakepayment/:id`} element={<ProtectedRoute element={<VehicleMakePayment />} allowedTypes={[1, 3]} />} />
            <Route path={`${config.baseurl}addvehiclebankdeposit`} element={<ProtectedRoute element={<AddVehicleBankDeposit />} allowedTypes={[1, 3]} />} />
            <Route path={`${config.baseurl}vehiclebankdepositlist`} element={<ProtectedRoute element={<VehicleBankDepositList />} allowedTypes={[1, 3]} />} />
            <Route path={`${config.baseurl}vehiclechequelist`} element={<ProtectedRoute element={<VehicleChequeList />} allowedTypes={[1, 3]} />} />
            <Route path={`${config.baseurl}addvehiclecheque`} element={<ProtectedRoute element={<AddVehicleCheque />} allowedTypes={[1, 3]} />} />
            <Route path={`${config.baseurl}vehicleestimatelist`} element={<ProtectedRoute element={<VehicleEstimateList />} allowedTypes={[1, 3]} />} />
            <Route path={`${config.baseurl}addvehicleestimate`} element={<ProtectedRoute element={<AddVehicleEstimate />} allowedTypes={[1, 3]} />} />
            <Route path={`${config.baseurl}vehiclejournalentry`} element={<ProtectedRoute element={<VehicleJournalEntry />} allowedTypes={[1, 3]} />} />
            <Route path={`${config.baseurl}addvehiclejournalentry`} element={<ProtectedRoute element={<AddVehicleJournalEntry />} allowedTypes={[1, 3]} />} />

          </Routes>
        </ScrollToTop>
      </BrowserRouter>
    </>
  );
}

export default App;
